<template>
    <div>
        <TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
            :slot_table_list="['operation','notify_status','is_read','sign_status']" ref="list" :rowKey="'id'" >
            <!-- <template slot="right_btn">
                <a :href="'/manage/crm/outbound_order_export' | export_form" target="_blank">
                    <a-button class="a_btn">导出</a-button>
                </a>
            </template> -->
            <template slot="operation" slot-scope="data">
                <a @click="go_detail(data.record)">查看</a>
            </template>
        </TableList>
    </div>
</template>
<script>
    // @ is an alias to /src
    import Form from "@/components/Form";
    import TableList from "@/components/TableList";
    import {
        get_order_list,
    } from '@/api/push'
    const columns = [{
            title: "订货单ID",
            dataIndex: "order_id",
        },
        {
            title: "经销商名称",
            dataIndex: "seller_name",
        },
        {
            title: "商品总件数",
            dataIndex: "total_count",
        },
        {
            title: "订单状态",
            dataIndex: "confirm_name",
        },
        // {
        //     title: "签字状态",
        //     dataIndex: "merchant_name",
        // },
        {
            title: "下单时间",
            dataIndex: "create_time",
        },
        {
            title: "类型",
            dataIndex: "type_name",
        },
        {
            title: "操作",
            scopedSlots: {
                customRender: "operation"
            }
        }
    ];


    export default {
        name: "Index",
        components: {
            Form,
            TableList,
        },
        data() {
            return {
                get_table_list: get_order_list,
                columns,
                visible: false,
                form_data_seo: {
                    ...this.$config.form_data_seo,
                    list: [
                        {
                            type: "text",
                            name: "keyword",
                            title: "关键词",
                            placeholder: "经销商名称/订货单ID/sap/drp",
                            options: {},
                        },
                        // {
                        //     type: "select",
                        //     name: "seller",
                        //     list: [],
                        //     title: "经销商",
                        //     options: {}
                        // },
                        {
                            type: "range_date",
                            name: "range_date",
                            title: "时间",
                            options: {
                            	initialValue: [this.$moment(new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)), 'YYYY/MM/DD'), this.$moment(new Date(), 'YYYY/MM/DD')],
                            },
                            start: {
                                name: 'start_time'
                            },
                            end: {
                                name: 'end_time'
                            },
                        },
                        {
                            type: "select",
                            name: "confirm_status",
                            list: [
                                {
                                    key: 1,
                                    value: '已确认'
                                },
                                {
                                    key: 2,
                                    value: '待确认'
                                },
                            ],
                            title: "状态",
                            options: {}
                        },
                    ],
                },
            };
        },
        created() {
            

        },
        methods: {
            //查看订货单详情
            go_detail(e) {
                this.$keep_route.add(this.$options.name);
                this.$router.push({
                    path: '/send_order/details',
                    query: {
                        id: e.id
                    }
                })
            },
            //导出
            // download() {
            //     outbound_order_export({
			// 		data: {
			// 			...this.$refs.list.seo_data
			// 		}
			// 	}).then(res => {
			// 		let a = document.createElement('a');
			// 		a.href = res.url;
            //         a.target = '_blank'
			// 		a.click();
			// 	})
            // }

        }
    };
</script>
